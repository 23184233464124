import { z } from 'zod';

/** Location dto schema. */
export const locationDtoSchema = z.object({
	id: z.number(),
	name: z.string(),
	loc_id: z.string(),
	can_quote: z.boolean(),
});

/** Location dto. */
export type LocationDto = Readonly<z.infer<typeof locationDtoSchema>>;
