import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

/**
 * Mapper for Sort.
 */
@Injectable({ providedIn: 'root' })
export class SortMapper {
	/**
	 * Maps sort values to DTO.
	 * @param sort Sort options.
	 * @param mapFieldToDto Maps the sort field to DTO.
	 */
	public toDto(sort: Sort | undefined | null, mapFieldToDto?: Readonly<Record<string, string>>): string | undefined {
		if (!sort || sort.direction === '') {
			return;
		}

		const field = mapFieldToDto ? mapFieldToDto[sort.active] : sort.active;

		// For descending sort use field name with '-' (e.g. '-field_name').
		return sort.direction === 'asc' ? field : `-${field}`;
	}
}
